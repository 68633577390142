import "./footer.css";
import { footer, footer_Link, social_links } from "../../Utils/Constants";
import fbIcon from "../../assets/svg/facebook.svg";
import instaIcon from "../../assets/svg/insta-svgrepo-com.svg";
import phonebIcon from "../../assets/svg/phone-svgrepo-com (1).svg";
import linkedinIcon from "../../assets/svg/linkedin-svgrepo-com (2).svg";
import youtubeIcon from "../../assets/svg/youtube-svgrepo-com.svg";
import logo from "../../assets/Img/Footer/logo.png";
import { useNavigate } from "react-router-dom";

const Footer_Link = () => {
  const navigate = useNavigate();
  return footer_Link.map((item) => {
    return (
      <div className="footer_link_div">
        <div className="footer_link_title">{item.title}</div>
        <div className="footer_sub_link_div">
          {item.subLink.map((subLink) => {
            return (
              <div
                className="footer_sub_link"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(subLink.link);
                }}
              >
                {subLink.title}
              </div>
            );
          })}
        </div>
      </div>
    );
  });
};

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="footer_box">
            <div className="footer_left_side">
              <div className="footer_social_container">
                <div className="footer_title">{footer.Simonson_Equipment}</div>
                <div className="footer_address">{footer.Address}</div>
                <div className="footer_sub_title">{footer.Located_Atlanta}</div>
                <div className="footer_social_box_group">
                  <a
                    href={social_links.facebook}
                    target="_blank"
                    className="footer_social_box"
                  >
                    <img src={fbIcon} alt="" className="footer_social_img" />
                  </a>
                  <a
                    href={social_links.instagram}
                    target="_blank"
                    className="footer_social_box"
                  >
                    <img src={instaIcon} alt="" className="footer_social_img" />
                  </a>
                  {/* <a className="footer_social_box">
                  <img src={phonebIcon} alt="" className="footer_social_img" />
                </a> */}
                  <a
                    href={social_links.linkedin}
                    target="_blank"
                    className="footer_social_box"
                  >
                    <img
                      src={linkedinIcon}
                      alt=""
                      className="footer_social_img"
                    />
                  </a>
                  <a
                    href={social_links.youtube}
                    target="_blank"
                    className="footer_social_box"
                  >
                    <img
                      src={youtubeIcon}
                      alt=""
                      className="footer_social_img"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer_right_side">
              <div className="footer_links_part">
                <Footer_Link />
              </div>
              <div>
                <img src={logo} alt="" className="footer_app_logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_second_header">
        <div className="container">
          <div className="footer_second">
            <div className="footer_second_link">{`© ${new Date().getFullYear()} ${
              footer.Simonson
            }`}</div>
            <div className="footer_second_link">
              {footer.Powered_by_Vizy}
              <a
                className="footer_link"
                target="_blank"
                href="https://vizybilitydms.com"
              >
                {footer.Vizybility}
              </a>
              .{footer.Designed_by}
              <a
                href="https://jessicagalfas.com/"
                target="_blank"
                className="footer_link"
              >
                {footer.Galfas_Media}
              </a>
              .
            </div>
            <div className="footer_second_link">{footer.Rights_Reserved}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
