import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationId } from "../../../api/url";
import { postMachinesList } from "../../../services/FeaturedListings";
import {
  Inventory_Machine_list,
  Inventory_Strings,
} from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Related_Posts.css";

const InventoryFeaturedList = ({ images }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return images?.map((item) => {
    return (
      <div className="inventory_list_box">
        <div className="inventory_list_img_box">
          <img
            onClick={() => {
              const encodeURICom = encodeURIComponent(
                `${
                  item.assetAdvertisementId
                }-${item?.primary_image?.name.replaceAll(" ", "-")}`
              );
              navigate(`/detail/${encodeURICom}`, { state: item });
              window.location.reload();
            }}
            src={item?.primary_image?.url}
            alt=""
            className="inventory_list_img"
          />
        </div>
        <div className="inventory_list_title">{item?.primary_image?.name}</div>
        {/* <div className="inventory_list_title">{item.price}</div> */}

        <div className="inventory_list_box_btn">
          <CustomButton
            lable={Inventory_Strings.LEARN_MORE}
            CustomButtonClass="inventory_list_Learen_More"
            onClick={() => {
              const encodeURICom = encodeURIComponent(
                `${
                  item.assetAdvertisementId
                }-${item?.primary_image?.name.replaceAll(" ", "-")}`
              );
              navigate(`/detail/${encodeURICom}`, { state: item });
              window.location.reload();
            }}
          />
        </div>
      </div>
    );
  });
};

const RelatedPosts = ({ relatedPostData }) => {
  return (
    <div className="Related_Posts_list_box">
      <div className="container">
        <div className="Related_Posts_list">
          <div className="inventory_featured_list_title">Related Posts</div>
          <div className="inventory_featured_list_div">
            <InventoryFeaturedList images={relatedPostData} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RelatedPosts;
