import api from "../api/api";
import * as url from "../api/url";

// Post Contact us Api
export const getTestimonial = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_TESTIMONIAL)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
