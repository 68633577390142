import "./EquipmentInfo.css";

const EquipmentInfo = () => {
  return (
    <div className="equipment_info_bg">
      <div className="container"></div>
    </div>
  );
};
export default EquipmentInfo;
