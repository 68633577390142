import "./Equipment_Search_By_Category.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Equipment_Inventory_List,
} from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import LeftIcon from "../../../assets/svg/left-arrow.svg";
import RightIcon from "../../../assets/svg/right-arrow.svg";
import loaders from "../../../assets/Img/Inventory/Heavy_Equipment.jpg";

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className="carousel-button-group">
      <button
        aria-label="Go to previous slide"
        className={"carousel-left-icon react-multiple-carousel__arrow--left"}
        onClick={() => previous()}
      >
        <img src={LeftIcon} alt="" />
      </button>
      <button
        aria-label="Go to next slide"
        className={"carousel-right-icon react-multiple-carousel__arrow--right"}
        onClick={() => next()}
      >
        <img src={RightIcon} alt="" />
      </button>
    </div>
  );
};
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};
const EquipmentSearchByCategory = ({
  isLoadingMachinesCategoryTypeList,
  machinesCategoryTypeList,
  handleSetAssetTypeId
}) => {
  return (
    <div className="EquipmentSearchByCategory_Box">
      <div className="container pt-40">
        <div className="Equipment_Search_Category_title pb-40">
          {Equipment_Inventory_List.SEARCH_CATEGORY}
        </div>
        {isLoadingMachinesCategoryTypeList ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        ) : (
          <>
            <Carousel
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={10000}
              itemClass="image-item"
              containerClass="carousel-container"
              arrows={false}
              customButtonGroup={<ButtonGroup />}
            >
              <div
                    className="Equipment_Search_By_Category_bg_img"
                    style={{
                      background: `linear-gradient(0deg, #00000091, #00000091),url(${loaders})`,
                    }}
                    onClick={()=>handleSetAssetTypeId("")}
                  >
                    <div className="Equipment_Search_By_title">
                      {"All"}
                    </div>
                  </div>
              {machinesCategoryTypeList?.map((item) => {
                return (
                  <div
                    className="Equipment_Search_By_Category_bg_img"
                    style={{
                      background: `linear-gradient(0deg, #00000091, #00000091),url(${loaders})`,
                    }}
                    onClick={()=>handleSetAssetTypeId(item?.AssetTypeId)}
                  >
                    <div className="Equipment_Search_By_title">
                      {item?.AssetTypeName}
                    </div>
                  </div>
                );
              })}
            </Carousel>
            <div className="Equipment_Search_box_btn">
              <CustomButton
                lable={Equipment_Inventory_List.VIEW_ALL}
                CustomButtonClass="equipment_inventory_load_more"
                onClick={()=>handleSetAssetTypeId(null)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default EquipmentSearchByCategory;
