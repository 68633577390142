import api from "../api/api";
import postApi from "../api/postApi";
import * as url from "../api/url";

// Post Machine List
export const postMachinesList = (payload) => {
  return new Promise(async (resolve, reject) => {
    return postApi
      .post(url.GET_MACHINES_LIST, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};


// Get all Machine Category List
export const getAllMachinesCategoryList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_CATEGORY_LIST)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};


// Get all Machine Category List
export const getAllMachinesManufacturerList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.GET_MANUFACTURER_LIST)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
