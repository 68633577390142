import { Equipment_content, Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Equipment_What_More.css"
const EquipmentWhatMore = () => {
  return (
    <div className="EquipmentWhatMore_box">
      <div className="container">
        <div className="EquipmentWhatMore_title">{Equipment_content.WANT_MORE}</div>
        <div className="EquipmentWhatMore_title_2">{Equipment_content.DONT_SEE}</div>
        <div className="EquipmentWhatMore_title_line"></div>
        <div className="inventory_info_learn_more_btn_box" >
          <CustomButton
            lable={Inventory_Strings.CALL_NOW}
            CustomButtonClass="EquipmentWhatMore_info_learn_more_btn"
          />
        </div>
      </div>
    </div>
  );
};
export default EquipmentWhatMore;
