import { About } from "../../../Utils/Constants.js";
import { About_Us_List } from "../../../Utils/Constants.js";
import "./AboutBanner.css";

const AboutBannerList = () => {
  return About_Us_List.map((item) => {
    return (
      <div className="About_Banner_List">
        <img src={item.img} alt=""  className="About_Banner_img"/>
        <div className="About_Banner_name">{item.name}</div>
        <div className="About_Banner_name">{item.email}</div>
        <div className="About_Banner_name">{item.number}</div>
      </div>
    );
  });
};

const AboutBanner = () => {
  return (
    <div className="about_banner_box">
      <div className="container">
        <div className="about_us_banner_title">{About.ABOUT_US}</div>
        <div className="about_us_banner_list_box">
          <AboutBannerList />
        </div>
        <div className="yard_address">
          {About.Yard_Address} : {About.Yard_Address_String}
        </div>
      </div>
    </div>
  );
};
export default AboutBanner;
