import { useNavigate } from "react-router-dom";
import {
  Equipment_Inventory_List,
  Inventory_Strings,
  Machine_List,
} from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import CloseIcon from "../../../assets/Img/Detail/close-icon.png";
import "./Equipment_Inventory_List_Banner.css";
import { useState } from "react";

const MachineList = ({ inventoryList }) => {
  const navigate = useNavigate();
  return inventoryList.map((item) => {
    return (
      <div className="Machine_List_Box">
        <div className="MachineList_img_Box">
          <img
            src={item?.primary_image?.url}
            alt=""
            className="Machine_List_Img"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              const encodeURICom = encodeURIComponent(
                `${
                  item.assetAdvertisementId
                }-${item?.primary_image?.name.replaceAll(" ", "-")}`
              );
              navigate(`/detail/${encodeURICom}`, { state: item });
            }}
          />
        </div>
        <div className="Machine_List_Title">{item?.primary_image?.name}</div>
        <div className="Machine_List_Price">
          {item?.advertised_price?.currency?.html_entity}
          {item?.advertised_price?.fractional}
        </div>
        <div className="inventory_list_box_btn">
          <CustomButton
            lable={Inventory_Strings.LEARN_MORE}
            CustomButtonClass="inventory_list_Learen_More"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              const encodeURICom = encodeURIComponent(
                `${
                  item.assetAdvertisementId
                }-${item?.primary_image?.name.replaceAll(" ", "-")}`
              );
              navigate(`/detail/${encodeURICom}`, { state: item });
            }}
          />
        </div>
      </div>
    );
  });
};

const EquipmentInventoryListBanner = ({
  isLoading,
  inventoryList,
  totalRecords,
  limit,
  page,
  handleNextPage,
}) => {
  const handleRemoveSearchFilter = () => {
    window.location.reload();
    localStorage.removeItem("searchFilter");
  };

  return (
    <div className="container">
      <div className="about_inventory_title_1">
        {Equipment_Inventory_List.INVENTORY}
      </div>
      <div className="about_inventory_title_2">
        {Equipment_Inventory_List.Explor_Selection}
      </div>
      <div className="about_inventory_keyword_box">
      {localStorage.getItem("searchFilter") !== undefined && localStorage.getItem("searchFilter") !== "" && localStorage.getItem("searchFilter") !== null  ? (
        <div className="about_inventory_btn_filter_key_word">
          Search Keyword : {localStorage.getItem("searchFilter")}
          <img
            src={CloseIcon}
            alt=""
            className="about_inventory_btn_filter_icon"
            onClick={handleRemoveSearchFilter}
          />
        </div>
      ):null}
      </div>
      {isLoading && inventoryList.length == 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: "70vh",
          }}
        >
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : inventoryList.length === 0 ? (
        <>
          <div
            className=" flex justify-center items-center"
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "30px",
              height: "60vh",
            }}
          >
            <div className="spinner-container no_data_text">No Data Found</div>
          </div>
        </>
      ) : inventoryList.length !== 0 && isLoading ? (
        <>
          <div className="equipment_inventory_list">
            <MachineList inventoryList={inventoryList} />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="equipment_inventory_list">
            <MachineList inventoryList={inventoryList} />
          </div>
          {page < Math.ceil(totalRecords / limit) && (
            <div className="inventory_list_box_btn">
              <CustomButton
                onClick={handleNextPage}
                lable={Equipment_Inventory_List.LOAD_MORE}
                CustomButtonClass="equipment_inventory_load_more"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default EquipmentInventoryListBanner;
