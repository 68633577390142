import { useState } from "react";
import { Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_equipment_search.css";
import { useNavigate } from "react-router-dom";

const InventoryEquipmentSearch = ({ pageRefresh, setPageRefresh }) => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleSearchBtn = () => {
    if (searchText !== "") {
      localStorage.setItem("searchFilter", searchText);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (pageRefresh !== undefined) {
        setPageRefresh(!pageRefresh);
      }
      navigate("/inventory");
      // window.location.reload();
    }
  };
  return (
    <div className="inventory_equipment_search_box">
      <div className="">
        <div className="inventory_equipment_search_title">
          {Inventory_Strings.EQUIPMENT_SEARCH}
        </div>
        <div className="inventory_equipment_search_title_2">
          {Inventory_Strings.SEARCH_MANUFACTURER}
        </div>
        <div className="inventory_equipment_search">
          <input
            type="text"
            className="inventory_equipment_search_input"
            placeholder="Search Equipment"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <CustomButton
            lable={Inventory_Strings.SEARCH}
            CustomButtonClass="inventory_equipment_search_btn"
            onClick={handleSearchBtn}
          />
        </div>
      </div>
    </div>
  );
};
export default InventoryEquipmentSearch;
