import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationId } from "../../../api/url";
import { postMachinesList } from "../../../services/FeaturedListings";
import {
  Inventory_Machine_list,
  Inventory_Strings,
} from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_featured_listings.css";

const InventoryFeaturedList = ({ images }) => {
  const navigate = useNavigate();
  return images?.map((item) => {
    return (
      <div className="inventory_list_box">
        <div className="inventory_list_img_box">
          <img
            src={item?.image}
            alt=""
            className="inventory_list_img"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              const encodeURICom = encodeURIComponent(
                `${item.assetAdvertisementId}-${item?.name}`
              );
              navigate(`/detail/${encodeURICom}`, { state: item });
            }}
          />
        </div>
        <div className="inventory_list_title">{item?.name}</div>
        {/* <div className="inventory_list_title">{item.price}</div> */}

        <div className="inventory_list_box_btn">
          <CustomButton
            lable={Inventory_Strings.LEARN_MORE}
            CustomButtonClass="inventory_list_Learen_More"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              const encodeURICom = encodeURIComponent(
                `${item.assetAdvertisementId}-${item?.name}`
              );
              navigate(`/detail/${encodeURICom}`, { state: item });
            }}
          />
        </div>
      </div>
    );
  });
};

const InventoryFeaturedListings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const GetMachinesList = () => {
    setIsLoading(true);
    let payload = {
      PageSize: 3,
      PageNumber: 1,
      IsFeatured: true,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        let showImgList = [];
        res?.data?.machines?.map?.((item, index) => {
          showImgList[index] = {
            ...item,
            image: item?.primary_image?.url,
            caption: `<div  class="bg-[#000] responsive_text_field" style="background: rgba(0,0,0,0.5); width: 90%; margin: auto; text-align:right; padding:0px 10px ">
                        ${item?.year}&nbsp;&nbsp;&nbsp;${item?.model?.number}&nbsp;&nbsp;&nbsp;${item?.model?.manufacturer?.name} -  Click for Details
                      </div>`,
            assetAdvertisementId: item?.assetAdvertisementId,
            name: item?.primary_image?.name.replaceAll(" ", "-"),
          };
        });
        setImages(showImgList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetMachinesList();
  }, []);

  return (
    <div className="inventory_featured_list_box">
      <div className="container">
        <div className="inventory_featured_list">
          <div className="inventory_featured_list_title">
            {Inventory_Strings.FEATURED_LISTINGS}
          </div>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                height: "70vh",
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          ) : images.length === 0 ? (
            <>
              <div
                className=" flex justify-center items-center"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "30px",
                  height: "10vh",
                }}
              >
                <div className="spinner-container no_data_text">
                  No Data Found
                </div>
              </div>
            </>
          ) : (
            <div className="inventory_featured_list_div">
              <InventoryFeaturedList images={images} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default InventoryFeaturedListings;
