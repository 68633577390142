import { About, Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./AboutInfo.css";
const AboutInfo = () => {
  return (
    <div className="about_info_box">
      <div className="container">
        <div className="about_info">
          {About.Lorem_amet}
          <div className="inventory_about_info_btn_box">
            <CustomButton
              lable={Inventory_Strings.CONTACT_US}
              CustomButtonClass="about_equipment_search_btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutInfo;
