import AboutInfoData from "../../components/About/AboutInfoData/AboutInfoData";
import AboutBanner from "../../components/About/About_banner/AboutBanner";
import AboutInfo from "../../components/About/About_Info/AboutInfo";
import AboutInventory from "../../components/About/About_Inventory/AboutInventory";
import AboutUSInfo from "../../components/About/About_us_Info/AboutUSInfo";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import InventoryFeaturedListings from "../../components/Inventory/InventoryFeaturedListings/Inventory_featured_listings";
import InventoryEquipmentNewsLatter from "../../components/Inventory/Inventory_equipment_news_latter/Inventory_equipment_news_latter";
import InventoryEquipmentSearch from "../../components/Inventory/Inventory_equipment_search/Inventory_equipment_search";
import "./About.css";
const About = () => {
  return (
    <div>
      <div className="About_Box">
        <Header  activeTab={"About"}/>
        <AboutBanner />
      </div>
      <AboutInfo />
      <AboutInventory/>
      <AboutUSInfo/>
      <InventoryFeaturedListings />
      <InventoryEquipmentSearch />
      <AboutInfoData />
      <InventoryEquipmentNewsLatter />
      <Footer />
    </div>
  );
};
export default About;
