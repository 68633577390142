import { About } from "../../../Utils/Constants";
import "./About_us_Info.css";
const AboutUSInfo = ({ ABOUT_INVENTORY_DESCRIPTION }) => {
  return (
    <div className="About_US_Info_box">
      <div className="container">
        <div className="AboutUSInfo_title">
          {ABOUT_INVENTORY_DESCRIPTION || About.Lorem_amet}
        </div>
      </div>
    </div>
  );
};
export default AboutUSInfo;
