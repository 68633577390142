import { useNavigate } from "react-router-dom";
import {
  Inventory_Help_List,
  Inventory_Strings,
} from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_help.css";

const InventoryHelpList = () => {
  const navigate = useNavigate();

  return Inventory_Help_List.map((item) => {
    return (
      <div className="inventory_help_list_box">
        <div
          className="inventory_help_list_bg_img"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            navigate(item.link);
          }}
        >
          {item.title}
        </div>
        <div className="inventory_help_list_description">
          {item.description}
        </div>

        <div className="inventory_Help_Learen_More_btn_box">
          <CustomButton
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate(item.link);
            }}
            lable={Inventory_Strings.LEARN_MORE}
            CustomButtonClass="inventory_Help_Learen_More"
          />
        </div>
      </div>
    );
  });
};

const InventoryHelp = () => {
  return (
    <div className="inventory_help_box">
      <div className="container">
        <div className="inventory_help_title">
          {Inventory_Strings.WERE_HERE_TO_HELP}
        </div>
        <div className="inventory_help_list_div">
          <InventoryHelpList />
        </div>
      </div>
    </div>
  );
};
export default InventoryHelp;
