import { Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_Info.css";
const InventoryInfo = () => {
  return (
    <div className="inventory_info_bg">
      <div className="container">
        <div className="inventory_info_title_1">
          {Inventory_Strings.NO_MATTER}
        </div>
        <div className="inventory_info_title_2">
          {Inventory_Strings.LEARN_CUSTOMERS}
        </div>
        <div className="inventory_info_learn_more_btn_box">
          <CustomButton
            lable={Inventory_Strings.LEARN_MORE}
            CustomButtonClass="inventory_info_learn_more_btn"
          />
        </div>
      </div>
    </div>
  );
};
export default InventoryInfo;
