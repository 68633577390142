import { About, About_Inventory_Logo_List } from "../../../Utils/Constants";
import Logo1 from "../../../assets/Img/About/Equipment_Logos.png";
import Logo2 from "../../../assets/Img/About/Equipment_Logos_3.png";
import "./AboutInventory.css";

const AboutInventoryLogoList = () => {
  return About_Inventory_Logo_List.map((item) => {
    return <img src={item.img} alt="" className="About_Inventory_List_Img" />;
  });
};

const AboutInventory = () => {
  return (
    <>
      <div className="about_inventory_box">
        <div className="container">
          <div className="about_inventory_title_1">{About.About_Inventory}</div>
          <div className="about_inventory_title_2">
            {About.About_Includeing}
          </div>
          <div className="about_inventory_list_box">
            <img src={Logo1} alt="" className="About_Inventory_List_Img" />
            <img src={Logo2} alt="" className="About_Inventory_List_Img About_Inventory_List_Img_2" />
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutInventory;
