import Carousel from "react-multi-carousel";
import LeftIcon from "../../assets/svg/left-arrow.svg";
import RightIcon from "../../assets/svg/right-arrow.svg";
import "react-multi-carousel/lib/styles.css";
import "./AttachmentsList.css"


const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
        carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;

    return (
        <div className="carousel-button-group">
            <button
                aria-label="Go to previous slide"
                className={"carousel-left-icon react-multiple-carousel__arrow--left"}
                onClick={() => previous()}
            >
                <img src={LeftIcon} alt="" />
            </button>
            <button
                aria-label="Go to next slide"
                className={"carousel-right-icon react-multiple-carousel__arrow--right"}
                onClick={() => next()}
            >
                <img src={RightIcon} alt="" />
            </button>
        </div>
    );
};

const XHDAttachmentsList = ({ list }) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 768, min: 679 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 679, min: 0 },
            items: 1,
        },
    };

    return (
        <div className="xhd-main-box">
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={10000}
                itemClass="image-item"
                containerClass="carousel-container"
                arrows={false}
                // customButtonGroup={<ButtonGroup />}
            >
                {list?.map((item) => {
                    return (
                        <div class="responsive-xhd-div">
                            <img src={item.img} className="responsive-xhd-img" />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    )
}
export default XHDAttachmentsList