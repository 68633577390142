import "./inventory_benner.css";
import SimonsonImg from "../../../assets/Img/Home/Simonson_Logo_PNG.png";
import { Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import { useNavigate } from "react-router-dom";

const InventoryLogoMapFun = ({ InventoryBennerText }) => {
  return InventoryBennerText.map((item) => {
    return <div className="inventory_benner_logo_text">{item}.</div>;
  });
};

const InventoryBenner = () => {
  const navigate = useNavigate(); 
  const InventoryBennerText = [
    Inventory_Strings.BUY,
    Inventory_Strings.SELL,
    Inventory_Strings.RENT,
    Inventory_Strings.TRADE,
    Inventory_Strings.CONSIGN,
  ];

  return (
    <>
      <div className="inventory_benner_div">
        <img src={SimonsonImg} alt="" className="inventory_benner_logo" />
        <div className="inventory_benner_logo_group">
          <InventoryLogoMapFun InventoryBennerText={InventoryBennerText} />
        </div>
        <div>
          <CustomButton
            lable={Inventory_Strings.FIND_EQUIPMENT}
            CustomButtonClass="inventory_find_equiment_btn"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate('/inventory');
            }}
          />
        </div>
      </div>
    </>
  );
};
export default InventoryBenner;
