import { useRef, useState } from "react";
import {
  Contact_Content,
  ReCAPTCHA_sitekey,
  rent_string,
  sell_string,
} from "../../Utils/Constants";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import { ApplicationId } from "../../api/url";
import { postContactUs } from "../../services/contactUsService";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";
import FormInput from "../../components/formInput/formInput";
import ReCAPTCHA from "react-google-recaptcha";

const Rent = () => {
  const [showCode, setShowCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const reCaptchaRef = useRef(null);
  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
      <div style="font-size:25px">
        <div>
        <b>Hello, Please find the details below for rental the machine.</b>
          <table>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.Name ?? ""
              }
              </td>
            </tr>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.Phone ?? ""
              }
              </td>
            </tr>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.Email ?? ""
              }
              </td>
            </tr>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Make</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.Make ?? ""
              }
              </td>
            </tr>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Model</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.Model ?? ""
              }
              </td>
            </tr>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Rental Start Date</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.RentalStartDate ?? ""
              }
              </td>
            </tr>
            <tr style="border-top:1px solid #C8C8C8">
              <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Notes</td>
              <td style="font-size:16px;color:#86848e;font-weight:bold;">${
                value?.Notes ?? ""
              }
              </td>
            </tr>
          </table>
        <div>
      </div>
    `;
    setIsLoading(true);
    let payload = {
      HtmlContent: htmlForm,
      ApplicationId: ApplicationId,
      Subject: "Looking For Rent Request Received",
    };
    postContactUs(payload)
      .then((res) => {
        toast.success(res?.data);
        reCaptchaRef?.current?.reset();
        setIsLoading(false);

        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };

  const AccSchema = Yup.object().shape({
    Email: Yup.string()
      .email("Enter Valid Email Address")
      .required("Please Enter E-mail Address"),
    Phone: Yup.string().required("Please Enter Phone Number"),
    Name: Yup.string().required("Please Enter Name"),
    Make: Yup.string().required("Please Enter Make"),
    Model: Yup.string().required("Please Enter Model"),
    RentalStartDate: Yup.string().required("Please Enter Rental Start Date"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  return (
    <>
      <div>
        <div className="About_Box">
          <Header activeTab={"Rent"} />
        </div>
        <div className="container">
          <div className="rent_box">
            <div className="rent_box_sub_title">{rent_string.hedging}</div>
            <div className="rent_box_heading">{rent_string.subHeading}</div>
          </div>
        </div>

        <div className="CONTACT_FORM_Box">
          <div className="container CONTACT_FORM_div">
            <div className="CONTACT_FORM__Header">
              {Contact_Content?.Tell_US_MORE}
            </div>
            <div className="Modal">
              {isLoading && (
                <div className="loader_Box">
                  <div className="loader"></div>
                </div>
              )}
              <div>
                <Formik
                  initialValues={{
                    reCaptcha: "",
                    Name: "",
                    Phone: "",
                    Email: "",
                    Make: "",
                    Model: "",
                    RentalStartDate: "",
                    SellPrice: "",
                    Notes: "",
                  }}
                  validationSchema={AccSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmitForm(values, resetForm);
                  }}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="font_Grid gap-8">
                        <FormInput
                          title="Name"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="Name"
                          name="Name"
                          type="text"
                        />
                        <FormInput
                          title="Phone"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="Phone"
                          name="Phone"
                          type="number"
                        />
                        <FormInput
                          title="Email"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="Email"
                          name="Email"
                          type="email"
                        />
                        <FormInput
                          title="Make"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="Make"
                          name="Make"
                          type="text"
                        />

                        <FormInput
                          title="Model"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="Model"
                          name="Model"
                          type="text"
                        />

                        <FormInput
                          title="Rental Start Date"
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          id="RentalStartDate"
                          name="RentalStartDate"
                          type="date"
                        />
                      </div>
                      <div>
                        <div className="font_Grid mb-3 flex flex-col">
                          <label className="form_Message_label">Notes</label>
                          <textarea
                            id="Notes"
                            name="Notes"
                            rows="3"
                            className="form-control p-2"
                            value={values.Notes}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      
                      <div className="ReCAPTCHA_Box">
                          <ReCAPTCHA
                            ref={reCaptchaRef}
                            size="normal"
                            sitekey={ReCAPTCHA_sitekey}
                            onChange={(captchaCode) => {
                              setFieldValue("reCaptcha", captchaCode);
                            }}
                          />
                          {errors?.reCaptcha && touched?.reCaptcha && (
                            <div
                              style={{ fontSize: 14, textAlign: "left" }}
                              className="text-red-600"
                            >
                              {errors?.reCaptcha}
                            </div>
                          )}
                          <button
                            type="submit"
                            className={`SUBMIT_Btn ${
                              isLoading
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                            disabled={isLoading}
                          >
                            SUBMIT
                          </button>
                        </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Rent;
