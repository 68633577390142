export const ApplicationId = "344268cc-9b4e-4c32-b987-e7c4b0c32b50";
export const ProURL = "https://dealerwebservice-api.azurewebsites.net/";
export const DevURL = "https://dealerwebservice-api-dev.azurewebsites.net/";
export const GET_MACHINES_LIST = `DealerWebApp/GetInventory`;
export const GET_CATEGORY_LIST = `DealerWebApp/GetTierWiseAssetCategorizationSummary?applicationId=${ApplicationId}`;
export const GET_MANUFACTURER_LIST = `DealerWebApp/GetMakeList?applicationId=${ApplicationId}`;
export const GET_TESTIMONIAL = `DealerWebApp/GetTestimonialSummary?applicationId=${ApplicationId}`;
export const POST_CONTACT_US = "DealerWebApp/SendContactEmail";
export const MACHINE_DETAILS = "DealerWebApp/GetInventoryDetailById?assetId=";
export const GET_RELATED_POST =
  "DealerWebApp/GetInventoryByCategory?assetTypeId=";
export const POST_SAVEADVERTISE = "DealerWebApp/SaveAdvertiseVisitLogs";
export const GET_MACHINES_DETAILS = `DealerWebApp/GetInventorySummaryById?applicationId=${ApplicationId}`;
export const URL = ProURL;
