import "./Xhd_Dealer.css";
import { Carousel } from "react-responsive-carousel";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import ServiceMainImg from "../../assets/Img/Service/ServiceMain.png";
import rightIcon from "../../assets/Img/Service/RightIcon.svg";
import {
  Inventory_Strings,
  ReCAPTCHA_sitekey,
  Service_Content,
  XHD_DEALER_Content,
  header,
} from "../../Utils/Constants";
import rightArrow from "../../assets/Img/Service/RightArrow.png";
import leftArrow from "../../assets/Img/Service/LeftArrow.png";
import shop from "../../assets/Img/Service/Shop_rate.png";
import * as Yup from "yup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Formik } from "formik";
import CustomButton from "../../components/custom_components/CustomButton";
import InventoryFeaturedListings from "../../components/Inventory/InventoryFeaturedListings/Inventory_featured_listings";
import InventoryEquipmentSearch from "../../components/Inventory/Inventory_equipment_search/Inventory_equipment_search";
import AboutInfoData from "../../components/About/AboutInfoData/AboutInfoData";
import InventoryEquipmentNewsLatter from "../../components/Inventory/Inventory_equipment_news_latter/Inventory_equipment_news_latter";
import AboutUSInfo from "../../components/About/About_us_Info/AboutUSInfo";
import { useEffect, useRef, useState } from "react";
import XHDAttachmentsList from "../../components/Xhd_Dealter/AttachmentsList";
import AttachmentA from "../../assets/Img/Attachment/attachment1.png";
import AttachmentB from "../../assets/Img/Attachment/attachment2.png";
import AttachmentC from "../../assets/Img/Attachment/attachment3.png";
import XHDAttachments from "../../assets/Img/Attachment/XHD_Attachments.png";
import ReCAPTCHA from "react-google-recaptcha";
import { ApplicationId } from "../../api/url";
import { toast } from "react-toastify";
import { postContactUs } from "../../services/contactUsService";
const XhdDealer = () => {
  const mainTableComponent = [
    {
      id: 1,
      mainTitle: "SKID STEER / COMPACT TRACK LOADER BUCKETS",
      subtitle: "Standard Duty Bucket",
      tableInfo: [
        {
          id: 1,
          value: '54"',
        },
        {
          id: 2,
          value: '67"',
        },
        {
          id: 3,
          value: '73"',
        },
        {
          id: 4,
          value: '79"',
        },
      ],
      description: "28% heavier than most manufactures standard duty bucket",
    },
    {
      id: 2,
      subtitle: "Heavy Duty Bucket",
      tableInfo: [
        {
          id: 1,
          value: '67"',
        },
        {
          id: 2,
          value: '73"',
        },
        {
          id: 3,
          value: '79"',
        },
        {
          id: 4,
          value: '82"',
        },
        {
          id: 5,
          value: '84"',
        },
      ],
      description:
        '4 x 1/2" thick wear strips for added strength, reinforced quick attach plate',
    },
    {
      id: 3,
      subtitle: "Severe Duty Bucket",
      tableInfo: [
        {
          id: 1,
          value: '67"',
        },
        {
          id: 2,
          value: '73"',
        },
        {
          id: 3,
          value: '79"',
        },
        {
          id: 4,
          value: '82"',
        },
        {
          id: 5,
          value: '84"',
        },
      ],
      description:
        '4 x 1/2" thick wear strips, 6" wear strip behind cutting edge, reinforced quick attach plate',
    },
    {
      id: 4,
      newTitle:
        "Tooth or smooth Buckets with Bolt on Cutting Edge, Custom Sizes and Options Available Upon Request ",
    },
    {
      id: 5,
      mainTitle: 'HEAVY DUTY 28" HIGH CAPACITY BUCKET',
      subtitle: "Heavy Duty High Capacity Bucket",
      tableInfo: [
        {
          id: 1,
          value: '67"',
          miniValue: "25.9 cu ft",
        },
        {
          id: 2,
          value: '73"',
          miniValue: "28.3 cu ft",
        },
        {
          id: 3,
          value: '79"',
          miniValue: "30.7 cu ft",
        },
        {
          id: 4,
          value: '82"',
          miniValue: "31.9 cu ft",
        },
        {
          id: 5,
          value: '84"',
          miniValue: "32.7 cu ft",
        },
      ],
      description: "Smooth bucket with bolt on cutting edge",
    },
    {
      id: 6,
      mainTitle: "SEVERE DUTY SCARP / DEMOLITION GRAPPLE BUCKET",
      subtitle: "Severe Duty Grapple",
      tableInfo: [
        {
          id: 1,
          value: '67"',
        },
        {
          id: 2,
          value: '73"',
        },
        {
          id: 3,
          value: '79"',
        },
        {
          id: 4,
          value: '82"',
        },
        {
          id: 5,
          value: '84"',
        },
      ],
      description:
        "Tooth or smooth buckets with bolt on cutting edge, independent tins, hoes routed internally for protection",
    },
    {
      id: 7,
      mainTitle: "HEAVY DUTY GRAPPLE",
      subtitle: "Heavy Duty Root Grapple",
      tableInfo: [
        {
          id: 1,
          value: '73"',
        },
        {
          id: 2,
          value: '82"',
        },
      ],
      description:
        '1/2" high strength steel, 9" tine spacing, 46.5" opening, replaceable pins',
    },
    {
      id: 7,
      subtitle: "Heavy Duty Rock Grapple",
      tableInfo: [
        {
          id: 1,
          value: '73"',
        },
        {
          id: 2,
          value: '84"',
        },
      ],
      description:
        '1/2" high strength steel, 4.875" tine spacing, 52" opening, replaceable pins',
    },
  ];

  const CustomTableContainer = [
    {
      id: 1,
      title: "Length",
      value: ["42", "48", "48", "60", "72"],
    },
    {
      id: 2,
      title: "Capacity",
      value: ["5,500 lbs", "5,500 lbs", "5,500 lbs", "5,500 lbs", "5,500 lbs"],
    },
  ];

  const attachmentList = [
    {
      img: AttachmentA,
    },
    {
      img: AttachmentB,
    },
    {
      img: AttachmentC,
    },
  ];
  const reCaptchaRef = useRef(null);

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    company: Yup.string().required("Please Enter Company Name"),
    email: Yup.string().email().required("Please Enter Email Address"),
    phone: Yup.string().required("Please Enter Phone Number"),
    message: Yup.string().required("Please Enter Message"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const CommonNameContainer = ({ fieldName, value, onChange, name }) => {
    return (
      <div className="Form_Main">
        <label className="contact-input-label Color_White">{fieldName}</label>
        <input
          type="text"
          className="contact_input"
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    );
  };

  const CommonFormContainer = ({ type, fieldName, value, onChange, name }) => {
    return (
      <div className="contact_grid_box_top contact_field Outer_field_label">
        <label className="contact-input-label Color_White">{fieldName}</label>
        <input
          type="text"
          className="contact_input"
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    );
  };

  const CommonFormContainerTextarea = ({
    type,
    fieldName,
    value,
    onChange,
    name,
  }) => {
    return (
      <div className="contact_grid_box_top contact_field Outer_field_label">
        <label className="contact-input-label Color_White">{fieldName}</label>
        <textarea
          rows="4"
          type="text"
          className="contact_input_textarea"
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    );
  };

  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
  <div style="font-size:25px">
    <div>
      <table>
        <tr style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.firstname ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${
            value?.lastname ?? ""
          }</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
            value?.email ?? ""
          }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
            value?.message ?? ""
          }</td>
        </tr>
      </table>
    <div>
  </div>
`;
    const htmlFormWidthInventory = `
    <div style="font-size:25px">
      <div>
        <table>
          <tr style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.firstname ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.lastname ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.company ?? ""
            }</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
              value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${
      value?.email ?? ""
    }</a></td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${
              value?.phone ?? ""
            }</td>
          </tr>
          <tr style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${
              value?.message ?? ""
            }</td>
          </tr>
        </table>
      <div>
    </div>
  `;

    let payload = {
      HtmlContent: htmlFormWidthInventory,
      ApplicationId: ApplicationId,
    };
    postContactUs(payload)
      .then((res) => {
        reCaptchaRef?.current?.reset();
        toast.success("email send successfully");
        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
      });
  };

  return (
    <div>
      <div className="Service_Box">
        <Header activeTab={"XhdDealer"} />
        <div className="container">
          <div>
            <h1 className="Header_Text">{XHD_DEALER_Content.SKIDSTEER}</h1>
          </div>

          <div className="mainContainerBox">
            {mainTableComponent?.map((item, index) => (
              <div className="mainDivStyle" id={index}>
                {item?.mainTitle && (
                  <div className="mainTitleStyle">
                    <p>{item?.mainTitle}</p>
                  </div>
                )}

                <div className="mainBoxStyle">
                  {item?.subtitle && (
                    <div className="mainInputStyle">
                      <table cellSpacing={0} cellPadding={0}>
                        <tr>
                          <td className="subtitleStyle">{item?.subtitle}</td>
                          {item?.tableInfo?.map((table, index) => (
                            <td key={index} className="valueTitleStyle">
                              {table?.value}
                            </td>
                          ))}
                        </tr>
                      </table>
                    </div>
                  )}
                  <p className="descriptionStyle">{item?.description}</p>
                  <p className="newTitleStyle">{item?.newTitle}</p>
                </div>
              </div>
            ))}

            <div className="mainTitleStyle">
              <p>HEAVY DUTY SKID STEER FORKS</p>
            </div>
            <div className="mainTableDivStyle">
              <table className="mainTableStyle" cellPadding={0} cellSpacing={0}>
                {CustomTableContainer?.map((item, index) => (
                  <tr className="innerTableDiv" id={index}>
                    <td className="tablerowStyle">{item?.title}</td>

                    {item?.value?.map((table, index) => (
                      <td key={index} className="tablerowStyle">
                        {table}
                      </td>
                    ))}
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <div className="Call_Now_Button">
            <CustomButton
              lable={header.CALL_NOW}
              CustomButtonClass="Contact_Btn_Style"
              // onClick={() => openModal()}
            />
          </div>
        </div>
        <XHDAttachmentsList list={attachmentList} />
        <div className="Xhd_Background_Image">
          <div className="container top_container_style">
            <div className="Main_Xhd_Request_Container">
              <div className="border_header_Style">
                <div className="border_style" />
                <h2 className="Main_header_Form Color_White">
                  {XHD_DEALER_Content.FORM_HEADER}
                </h2>
                <div className="border_style" />
              </div>
              <div className="Main_Sub_Header_Style">
                <p className="Sub_header Color_White">
                  {XHD_DEALER_Content.FORM_SUB_HEADER}
                </p>
              </div>
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  company: "",
                  email: "",
                  phone: "",
                  attachment: "",
                  message: "",
                  reCaptcha: "",
                }}
                validationSchema={AccSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmitForm(values, resetForm);
                }}
              >
                {({
                  errors,
                  touched,
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} className="Main_Form_Style">
                    <div className="Fname_Lname_Container">
                      <div className="Inner_Container">
                        <CommonNameContainer
                          fieldName={"First Name"}
                          value={values.firstname}
                          name={"firstname"}
                          onChange={(e) => handleChange(e)}
                        />
                        {errors.firstname && touched.firstname && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className="Error_message_style"
                          >
                            {errors.firstname}
                          </div>
                        )}
                      </div>
                      <div className="Inner_Container">
                        <CommonNameContainer
                          fieldName={"Last Name"}
                          value={values.lastname}
                          name={"lastname"}
                          onChange={(e) => handleChange(e)}
                        />
                        {errors.lastname && touched.lastname && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className="Error_message_style"
                          >
                            {errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>
                    <CommonFormContainer
                      fieldName={"Company"}
                      value={values.company}
                      name={"company"}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.company && touched.company && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.company}
                      </div>
                    )}
                    <CommonFormContainer
                      fieldName={"Email Address"}
                      value={values.email}
                      name={"email"}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.email && touched.email && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.email}
                      </div>
                    )}
                    <CommonFormContainer
                      fieldName={"Phone Number"}
                      value={values.phone}
                      name={"phone"}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.phone && touched.phone && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.phone}
                      </div>
                    )}

                    <CommonFormContainerTextarea
                      value={values.message}
                      name={"message"}
                      type={"textarea"}
                      onChange={(e) => handleChange(e)}
                      fieldName={
                        "Which type of attachment are you looking for?"
                      }
                    />

                    {errors.message && touched.message && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.message}
                      </div>
                    )}

                    <div className="contact_grid_box_top contact_field google_recaptch_box">
                      <ReCAPTCHA
                        ref={reCaptchaRef}
                        size="normal"
                        sitekey={ReCAPTCHA_sitekey}
                        onChange={(captchaCode) => {
                          setFieldValue("reCaptcha", captchaCode);
                          // onReCAPTCHAChange
                        }}
                      />
                      {errors.reCaptcha && touched.reCaptcha && (
                        <div
                          style={{ fontSize: 14, textAlign: "left" }}
                          className="error_message"
                        >
                          {errors.reCaptcha}
                        </div>
                      )}
                    </div>

                    <CustomButton
                      type="submit"
                      lable={Inventory_Strings.SUBMIT}
                      CustomButtonClass={"Submit_button"}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {/* <AboutUSInfo /> */}
      <InventoryFeaturedListings />
      <InventoryEquipmentSearch />
      <AboutInfoData />
      <InventoryEquipmentNewsLatter />

      <Footer />
    </div>
  );
};

export default XhdDealer;
