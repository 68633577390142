import { useEffect, useState } from "react";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import ImageGallery from "react-image-gallery";
import { getMachineDetailsByID, postMachinesList } from "../../services/detail";
import { currencyFormat, getUniqueListBy } from "../../Utils/helper";
import pdfFile from "../../assets/Img/Icone/pdf-file.svg";
import gmailIcon from "../../assets/Img/Icone/gmail.png";
import "./detail.css";
import "react-image-gallery/styles/css/image-gallery.css";
import CustomButton from "../../components/custom_components/CustomButton";
import RelatedPosts from "../../components/Detail/RelatedPosts/Related_Posts";
import { useLocation } from "react-router-dom";
import { ApplicationId } from "../../api/url";

const Detail = (props) => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [carouselImg, setCarouselImg] = useState([]);
  const [currentVideo, setCurrentVideo] = useState("");
  const [relatedPostData, setRelatedPostData] = useState(null);
  const [images, setImages] = useState();
  const [handleOpenContact, setHandleOpenContact] = useState(false);
  const [handleInventoryContactMenu, setHandleInventoryContactMenu] =
    useState();
  const [data, setData] = useState([]);
  const [singleImage, setSingleImage] = useState([]);

  const handleOpenContactMenu = (data) => {
    setHandleOpenContact(true);
    setHandleInventoryContactMenu(
      `${data.assetAdvertisementId}-${data?.year || ""}-${
        data?.manufacturer?.name
      }-${data?.model?.number}`
    );
  };

  const handleCloseContactMenu = () => {
    setHandleOpenContact(false);
    setHandleInventoryContactMenu("");
  };

  const getMachineDetails = () => {
    setIsLoading(true);
    const decode = decodeURIComponent(props?.match?.params?.id);
    let id = decode.split("-");
    // getMachineDetailsByID(2271)
    getMachineDetailsByID(state?.assetAdvertisementId)
      .then((res) => {
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const GetRelatedPostsList = (ID) => {
    setIsLoading(true);
    let payload = {
      AssetTypeId: ID,
      CategoryId: data?.categorization?.category?.id,
      PageSize: 3,
      PageNumber: 1,
      IsLatestFirst: true,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        let filterMachinesList = res?.data?.machines?.filter(
          (item) => item?.assetAdvertisementId !== data?.assetAdvertisementId
        );
        setRelatedPostData(filterMachinesList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleOnPressCall = () => {
    if (data?.dealer?.contact_user?.phone) {
      return `callto:${data?.dealer?.contact_user?.phone}`;
    } else if (data?.dealer?.contact_user?.email) {
      return `mailto:${data?.dealer?.contact_user?.email}`;
    } else {
      return "javascript:void(0)";
    }
  };

  const mailTemplate = () => {
    let mail = `mailto:?subject=Machine Details &body=%0D%0APlease find the equipment details as below%0D%0A
                    %0D%0ATitle%20:%20${data?.year > 0 && data?.year}${
      data?.manufacturer?.name && data?.manufacturer.name
    }${data?.model?.number && data?.model?.number}
                    %0D%0APrice%20:%20${
                      data?.advertised_price?.currency?.symbol
                    }${data?.advertised_price?.fractional}
                    %0D%0AManufacturer%20:%20${
                      data ? data?.manufacturer?.name : "-"
                    }
                    %0D%0AModel%20:%20${data ? data?.model?.number : "-"}
                    %0D%0AYear%20:%20 ${data ? data?.year : "-"}
                    %0D%0ACondition%20:%20${
                      data?.meter_reading ? "Used" : "New"
                    }
                    %0D%0AHours%20:%20${data ? data?.meter_reading : "-"}
                    %0D%0ALink%20:%20${window.location.href}
                    `;
    return mail;
  };

  useEffect(() => {
    getMachineDetails();
  }, []);

  useEffect(() => {
    if (data?.videos?.length) {
      let vidUrl;
      if (data?.videos[0].url.includes("v=")) {
        vidUrl = data?.videos[0].url.split("v=").pop();
      } else {
        vidUrl = data?.videos[0].url.split("/").pop();
      }
      setCurrentVideo(vidUrl);
    }
    if (data) {
      GetRelatedPostsList(data?.category?.machine_type_id);
      let imageData = data?.images?.map(({ url: image, ...rest }) => ({
        image,
        ...rest,
      }));
      setImages(imageData);
    }

    if (data) {
      let newCarouselImgArray = [];
      newCarouselImgArray.push({
        original: data?.primary_image?.url,
        thumbnail: data?.primary_image?.url,
      });
      setSingleImage(newCarouselImgArray);
    }
  }, [data]);

  useEffect(() => {
    if (images) {
      let uniqueData = getUniqueListBy(images, "id");
      let shortImag = uniqueData?.sort(function (a, b) {
        return a?.order - b?.order;
      });
      let allImg = shortImag;
      shortImag?.map((item, index) => {
        allImg[index] = {
          ...allImg[index],
          original: item?.image,
          thumbnail: item?.image,
        };
      });
      setCarouselImg(allImg);
    }
  }, [images]);

  return (
    <>
      {isLoading ? (
        <>
          <Header activeTab={"Detail"} />
          <div className="container">
            <div
              className=" flex justify-center items-center"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "30px",
                height: "60vh",
              }}
            >
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="detail_box">
            <Header
              activeTab={"Detail"}
              handleInventoryContactMenu={handleInventoryContactMenu}
              handleOpenContact={handleOpenContact}
              handleCloseContactMenu={handleCloseContactMenu}
            />
            <div className="container">
              {carouselImg?.length > 0 ? (
                <div className="detail_box_Gallery">
                  <ImageGallery
                    items={carouselImg}
                    infinite={true}
                    showPlayButton
                    autoPlay={true}
                    useBrowserFullscreen={false}
                    originalWidth={CarouselStyle.width}
                    originalHeight={CarouselStyle.height}
                    slideInterval={5000}
                  />
                </div>
              ) : (
                <ImageGallery
                  items={singleImage}
                  infinite={true}
                  showPlayButton
                  autoPlay={true}
                  useBrowserFullscreen={false}
                  originalWidth={CarouselStyle.width}
                  originalHeight={CarouselStyle.height}
                  slideInterval={5000}
                />
              )}
            </div>
          </div>
          <div className="container machine_detail_box">
            <div className="max-w-50">
              <label className="machine_detail_box_title">Specifications</label>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Title: </label>
                <label className="w-50">
                  {data?.year > 0 && data?.year}{" "}
                  {data?.manufacturer?.name && data?.manufacturer.name}{" "}
                  {data?.model?.number && data?.model?.number}
                </label>
              </div>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Price: </label>
                <label className="w-50">
                  {data?.advertised_price?.fractional != 0 ? (
                    <>
                      {data?.advertised_price?.currency?.symbol}{" "}
                      {currencyFormat(
                        Number(data?.advertised_price?.fractional)
                      )}
                    </>
                  ) : (
                    <a
                      href={handleOnPressCall()}
                      className="bg-cyan-600 text-white py-1 px-2 text-sm rounded disable:hover  "
                    >
                      Call for Pricing
                    </a>
                  )}
                </label>
              </div>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Manufacturer: </label>
                <label className="w-50">
                  {data ? data?.manufacturer?.name : "-"}
                </label>
              </div>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Model: </label>
                <label className="w-50">
                  {data ? data?.model?.number : "-"}
                </label>
              </div>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Year: </label>
                <label className="w-50">{data ? data?.year : "-"}</label>
              </div>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Condition: </label>
                {data?.meter_reading ? (
                  <label className="w-50">Used</label>
                ) : (
                  <label className="w-50">New</label>
                )}
              </div>
              <div className="machine_detail_fields">
                <label className="font-bold w-50">Hours: </label>
                <label className="w-50">
                  {data ? data?.meter_reading : "-"}
                </label>
              </div>

              <div className="machine_detail_title_box">
                <label className="machine_detail_box_title">Description </label>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.machine_description_html,
                }}
                className="machine_detail_title_box htmlTable"
              ></div>
            </div>

            <div className="machine_detail_left_boxs">
              <label className="machine_detail_box_title">Documents</label>
              <div className="detail_documents">
                {data?.documents?.map((document, key) => {
                  return (
                    <div className="detail_page_documents_box">
                      <a
                        href={document?.url}
                        download
                        style={{ color: "#2200CC" }}
                      >
                        {document?.name && (
                          <div className="machine_detail_left_img">
                            {/* <ReactTooltip /> */}
                            <img
                              data-tip={document?.name}
                              src={pdfFile}
                              width={"60px"}
                              className="mr-2"
                              alt=""
                            />
                            <span>{document?.name}</span>
                          </div>
                        )}
                      </a>
                    </div>
                  );
                })}
                {data && data?.documents && data?.documents?.length <= 0 && (
                  <span>*Documents not available</span>
                )}
              </div>
              {data?.videos?.length && currentVideo ? (
                <div className="machine_detail_left_videos">
                  <iframe
                    src={`https://www.youtube.com/embed/${currentVideo}`}
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen="allowfullscreen"
                    title="video"
                    className="machine_detail_left_videos_iframe"
                  />
                </div>
              ) : null}
              <div className="machine_detail_left_videos_box">
                {data?.videos?.map((videos, key) => {
                  return (
                    <div>
                      <CustomButton
                        onClick={() => {
                          const vidUrl = videos?.url?.split("v=").pop();
                          setCurrentVideo(vidUrl);
                        }}
                        lable={videos?.name}
                        CustomButtonClass="machine_detail_search_btn_video"
                      />
                    </div>
                  );
                })}
              </div>
              <CustomButton
                lable={` Get More informaiton`}
                onClick={() => {
                  handleOpenContactMenu(data);
                }}
                CustomButtonClass="machine_detail_search_btn machine_detail_box_title"
              />
              <div className="machine_detail_Share_box">
                <div className="machine_detail_Share_div">
                  <div className="machine_detail_Share_string">Share</div>
                  <div className="machine_detail_Share_div_string">
                    <a
                      href={mailTemplate()}
                      className="machine_detail_Share_div_a_link"
                    >
                      <img src={gmailIcon} alt="" className="email_icone" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {relatedPostData?.length !== 0 ? (
            <div>
              <RelatedPosts relatedPostData={relatedPostData} />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <Footer />
    </>
  );
};

const CarouselStyle = {
  width: "1080px",
  height: "600px",
  thumbnailWidth: "100px",
  captionStyle: {
    display: "none",
  },
  layout: {
    textAlign: "center",
    maxWidth: "1080px",
    maxHeight: "100%",
    minHeight: "100%",
    margin: "50px auto 90px auto",
  },
};
export default Detail;
