import { Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_equipment_news_latter.css";

const InventoryEquipmentNewsLatter = () => {
  return (
    <div className="inventory_equipment_news_latter_box">
      <div className="container">
        <div className="inventory_equipment_search_title">
          {Inventory_Strings.NEWSLETTER}
        </div>
        <div className="inventory_equipment_search_title_2">
          {Inventory_Strings.SIGN_UP}
        </div>
        <div className="inventory_equipment_search">
          <input
            type="text"
            className="inventory_equipment_subscribe_input"
            placeholder="Your email"
          />
          <CustomButton
            lable={Inventory_Strings.SUBSCRIBE}
            CustomButtonClass="inventory_equipment_search_btn"
          />
        </div>
      </div>
    </div>
  );
};
export default InventoryEquipmentNewsLatter;
