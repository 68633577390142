import "./Service.css";
import { Carousel } from "react-responsive-carousel";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import ServiceMainImg from "../../assets/Img/Service/ServiceMain.png";
import rightIcon from "../../assets/Img/Service/RightIcon.svg";
import { Inventory_Strings, Service_Content } from "../../Utils/Constants";
import rightArrow from "../../assets/Img/Service/RightArrow.png";
import leftArrow from "../../assets/Img/Service/LeftArrow.png";
import shop from "../../assets/Img/Service/Shop_rate.png";
import * as Yup from "yup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Formik } from "formik";
import CustomButton from "../../components/custom_components/CustomButton";
import InventoryFeaturedListings from "../../components/Inventory/InventoryFeaturedListings/Inventory_featured_listings";
import InventoryEquipmentSearch from "../../components/Inventory/Inventory_equipment_search/Inventory_equipment_search";
import AboutInfoData from "../../components/About/AboutInfoData/AboutInfoData";
import InventoryEquipmentNewsLatter from "../../components/Inventory/Inventory_equipment_news_latter/Inventory_equipment_news_latter";
import AboutUSInfo from "../../components/About/About_us_Info/AboutUSInfo";
import { useEffect, useState } from "react";

const Service = () => {
  const [viewBox, setViewBox] = useState("0 0 683 572"); // Initial viewBox dimensions

  const Service_Repair_Data = [
    { title: Service_Content.FASTAFFORDABLE },
    { title: Service_Content.EXPERTMACHANICS },
    { title: Service_Content.MIMIALTIME },
    { title: Service_Content.EQQUIPPEDSHOP },
    { title: Service_Content.ALLMAKESMODELS },
    { title: Service_Content.SHOPFIELDSERVICE },
  ];

  useEffect(() => {
    // Calculate the bounding box of the content
    const svgContent = document.getElementById("svgContent");
    const svgBoundingBox = svgContent.getBBox();

    // Set the viewBox dimensions based on the bounding box
    setViewBox(
      `${svgBoundingBox.x} ${svgBoundingBox.y} ${svgBoundingBox.width} ${svgBoundingBox.height}`
    );
  }, [Service_Repair_Data]);

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    company: Yup.string().required("Please Enter Company Name"),
    email: Yup.string().email().required("Please Enter Email Address"),
    phone: Yup.string().required("Please Enter Phone Number"),
  });

  const CommonNameContainer = ({ fieldName, value, onChange, name }) => {
    return (
      <div className="Form_Main">
        <label className="contact-input-label">{fieldName}</label>
        <input
          type="text"
          className="contact_input"
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    );
  };

  const CommonFormContainer = ({ fieldName, value, onChange, name }) => {
    return (
      <div className="contact_grid_box_top contact_field Outer_field_label">
        <label className="contact-input-label">{fieldName}</label>
        <input
          type="text"
          className="contact_input"
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    );
  };

  const onSubmitBtn = () => {
    alert("Form Submitted");
  };

  return (
    <div>
      <div className="Service_Box">
        <Header activeTab={"Service"} />

        <div className="container">
          <div>
            <h1 className="Header_Text">{Service_Content.SERVICE_REPAIR}</h1>
          </div>
          <div className="Service_Repair_Container">
            <div className="Service_Box_Banner">
              <div className="Main_ShopRate">
                <img src={shop} className="shopImage" />
                <div className="ShopRate_Text">
                  <div className="Dollars_hours">
                    <h1 className="Dollars_Text">{Service_Content.DOLLARS}</h1>
                    <p>{Service_Content.SLASH}</p>
                  </div>
                  <p className="Shop_Rate_Text">{Service_Content.SHOPRATE}</p>
                </div>
              </div>
              <div className="Carousel_Main">
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  showThumbs={false}
                  centerMode={false}
                  showStatus={false}
                  interval={5000}
                  renderArrowPrev={(clickHandler) => {
                    return (
                      <div onClick={clickHandler} className="LeftArrowStyle">
                        <img src={leftArrow} className="" />
                      </div>
                    );
                  }}
                  renderArrowNext={(clickHandler) => {
                    return (
                      <div onClick={clickHandler} className="RightArrowStyle">
                        <img src={rightArrow} className="" />
                      </div>
                    );
                  }}
                >
                  <img src={ServiceMainImg} />
                  <img src={ServiceMainImg} />
                  <img src={ServiceMainImg} />
                  <img src={ServiceMainImg} />
                  <img src={ServiceMainImg} />
                </Carousel>
              </div>
            </div>
            <svg
              id="ejrOpVhTyYa1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox={viewBox}
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              width="100%" // Set width to 100% to adapt to the container
              height="100%" // Set height to 100% to adapt to the container
            >
              <g id="svgContent">
                {Service_Repair_Data.map((item, index) => (
                  <g
                    key={index}
                    transform={`translate(0 ${index * 35 + 0.000001})`}
                  >
                    <image
                      href={rightIcon}
                      transform={`translate(${5.827059} 3)`}
                      x={54.827059}
                      y={106.301176}
                      width={20}
                      height={20}
                    />
                    <path
                      d={
                        index === 0
                          ? `M54.827059,106l296,1.345883l10,30h-${306}v-30Z`
                          : `M54.827059,106v30h${
                              306 + index * 10
                            }l-8.2023401293-30`
                      }
                      fill="none"
                      stroke="#707070"
                      strokeWidth="1.366"
                      strokeDasharray="5,2" // Adjust the numbers to change the dash pattern
                    />
                    <text
                      dx="0"
                      dy="0"
                      fontFamily="GoodTimes"
                      fontSize="15"
                      fontWeight="400"
                      fill="#fff"
                      // Adjust transform to center the text vertically and horizontally in the box
                      transform={`translate(${75.827059 + 10} ${
                        106.301176 + 20
                      })`}
                      strokeWidth="0"
                    >
                      <tspan y="0" fontWeight="400" strokeWidth="0">
                        {item.title}
                      </tspan>
                    </text>
                  </g>
                ))}
              </g>
            </svg>
          </div>
          <p className="Address_Style">{Service_Content.SERVICEADDRESS}</p>
        </div>
        <div className="Background_Image">
          <div className="container top_container_style">
            <div className="Main_Request_Container">
              <div className="border_header_Style">
                <div className="border_style" />
                <h2 className="Main_header_Form">
                  {Service_Content.FORM_HEADER}
                </h2>
                <div className="border_style" />
              </div>
              <div className="Main_Sub_Header_Style">
                <p className="Sub_header">{Service_Content.FORM_SUB_HEADER}</p>
              </div>
              <Formik
                initialValues={{
                  firstname: "",
                  lastname: "",
                  company: "",
                  email: "",
                  phone: "",
                  yearmakemodel: "",
                  serialnumber: "",
                }}
                validationSchema={AccSchema}
                onSubmit={() => {
                  onSubmitBtn();
                }}
              >
                {({ errors, touched, values, handleSubmit, handleChange }) => (
                  <form onSubmit={handleSubmit} className="Main_Form_Style">
                    <div className="Fname_Lname_Container">
                      <div className="Inner_Container">
                        <CommonNameContainer
                          fieldName={"First Name"}
                          value={values.firstname}
                          name={"firstname"}
                          onChange={(e) => handleChange(e)}
                        />
                        {errors.firstname && touched.firstname && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className="Error_message_style"
                          >
                            {errors.firstname}
                          </div>
                        )}
                      </div>
                      <div className="Inner_Container">
                        <CommonNameContainer
                          fieldName={"Last Name"}
                          value={values.lastname}
                          name={"lastname"}
                          onChange={(e) => handleChange(e)}
                        />
                        {errors.lastname && touched.lastname && (
                          <div
                            style={{ fontSize: 14, textAlign: "left" }}
                            className="Error_message_style"
                          >
                            {errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>
                    <CommonFormContainer
                      fieldName={"Company"}
                      value={values.company}
                      name={"company"}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.company && touched.company && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.company}
                      </div>
                    )}
                    <CommonFormContainer
                      fieldName={"Email Address"}
                      value={values.email}
                      name={"email"}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.email && touched.email && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.email}
                      </div>
                    )}
                    <CommonFormContainer
                      fieldName={"Phone Number"}
                      value={values.phone}
                      name={"phone"}
                      onChange={(e) => handleChange(e)}
                    />
                    {errors.phone && touched.phone && (
                      <div
                        style={{ fontSize: 14, textAlign: "left" }}
                        className="Error_message_style"
                      >
                        {errors.phone}
                      </div>
                    )}
                    <CommonFormContainer
                      value={values.yearmakemodel}
                      name={"yearmakemodel"}
                      onChange={(e) => handleChange(e)}
                      fieldName={"Year Make Model"}
                    />
                    <CommonFormContainer
                      value={values.serialnumber}
                      name={"serialnumber"}
                      onChange={(e) => handleChange(e)}
                      fieldName={"Serial Number"}
                    />

                    <CustomButton
                      type="submit"
                      lable={Inventory_Strings.SUBMIT}
                      CustomButtonClass={"Submit_button"}
                    />
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <AboutUSInfo
        ABOUT_INVENTORY_DESCRIPTION={Service_Content.ABOUT_INVENTORY}
      />
      <InventoryFeaturedListings />
      <InventoryEquipmentSearch />
      <AboutInfoData />
      <InventoryEquipmentNewsLatter />

      <Footer />
    </div>
  );
};

export default Service;
