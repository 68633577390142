import { useNavigate } from "react-router-dom";
import { Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_explore.css";

const InventoryExplore = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/inventory");
  };

  return (
    <div className="inventory_explore_box">
      <div className="container">
        <div className="inventory_explore">
          <div className="inventory_explore_title">
            {Inventory_Strings.EXPORE}
          </div>
          <div className="inventory_explore_title">
            {Inventory_Strings.INVENTORY}
          </div>
          <div className="for_sale_btn_box">
            <CustomButton
              lable={Inventory_Strings.FOR_SELE}
              CustomButtonClass="for_sale_btn"
              onClick={handleNavigation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InventoryExplore;
