import "./header.css";
import { header } from "../../Utils/Constants";
import PhoneIcon from "../../assets/Img/Home/telephone_icon_4.png";
import NavigationLinkHeader from "./navigationLinkHeader";

const Header = ({
  activeTab,
  handleOpenContact,
  handleCloseContactMenu,
  handleInventoryContactMenu,
  pageRefresh,
  setPageRefresh,
}) => {
  return (
    <div>
      <div className="phone_number_header">
        <img src={PhoneIcon} alt="" className="phone_number_icone" />
        {header.CALL_NOW}
        <div className="middle_dot">{":"}</div>
        <a className="phone_number_header_a_tag" href={`tel:${header.NUMBER}`}>
          {header.NUMBER}
        </a>
      </div>
      <NavigationLinkHeader
        pageRefresh={pageRefresh}
        setPageRefresh={setPageRefresh}
        activeTab={activeTab}
        handleOpenContact={handleOpenContact}
        handleCloseContactMenu={handleCloseContactMenu}
        handleInventoryContactMenu={handleInventoryContactMenu}
      />
    </div>
  );
};
export default Header;
