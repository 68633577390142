import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
const EquipmentSearchModel = ({
  pageRefresh,
  setPageRefresh,
  closeSearchModal,
}) => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  const handleSearchBtn = () => {
    if (searchText !== "") {
      localStorage.setItem("searchFilter", searchText);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (pageRefresh !== undefined) {
        setPageRefresh(!pageRefresh);
      }
      navigate("/inventory");
      closeSearchModal();
      // window.location.reload();
    }
  };
  return (
    <>
      <div className="inventory_equipment_search_box_Model">
        <div className="">
          <div className="inventory_equipment_search_title">
            {Inventory_Strings.EQUIPMENT_SEARCH}
          </div>
          <div className="inventory_equipment_search_title_2">
            {Inventory_Strings.SEARCH_MANUFACTURER}
          </div>
          <div className="inventory_equipment_search">
            <input
              type="text"
              className="inventory_equipment_search_input_model"
              placeholder="Search Equipment"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="button_grid_model">
          <CustomButton
            type="button"
            lable={"Cancel"}
            CustomButtonClass="cancel_btn"
            onClick={closeSearchModal}
          />
          <CustomButton
            type="button"
            CustomButtonClass="inventory_equipment_search_btn_Model"
            lable={"Search"}
            onClick={handleSearchBtn}
          />
        </div>
      </div>
    </>
  );
};
export default EquipmentSearchModel;
