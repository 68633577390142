import { useEffect, useState } from "react";
import { getTestimonial } from "../../../services/testimonialSummary";
import { Inventory_Strings, Testimonoal_list } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./Inventory_testimonials.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import LeftArrowImg from "../../../assets/svg/left-arrow.svg";
import RightArrowImg from "../../../assets/svg/right-arrow.svg";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ next, previous, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className="carousel-button-group">
      <button
        aria-label="Go to previous slide"
        className={
          "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        }
        onClick={() => previous()}
      >
        <img src={LeftArrowImg} alt="" />
      </button>
      <button
        aria-label="Go to next slide"
        className={
          "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        }
        onClick={() => next()}
      >
        <img src={RightArrowImg} alt="" />
      </button>
    </div>
  );
};

const InventoryTestimonoalList = ({ testimonialData }) => {
  return testimonialData.length <= 3 ? (
    testimonialData?.map((item) => {
      return (
        <div className="inventory_testimonoal_box">
          <div className="inventory_testimonoal_Name">
            {item?.Name}
          </div>
            <div className="inventory_testimonoal_title">{item?.Title}</div>
          <div className="inventory_testimonoal_title_under_line"></div>
          <div
            dangerouslySetInnerHTML={{
              __html: item?.TestimonialText,
            }}
            className="inventory_testimonoal_description"
          ></div>
        </div>
      );
    })
  ) : (
    <Carousel
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={10000}
      itemClass="image-item"
      containerClass="carousel-container"
      arrows={false}
      customButtonGroup={<ButtonGroup />}
    >
      {testimonialData?.map((item) => {
        return (
          <div className="inventory_testimonoal_box">
            <div className="inventory_testimonoal_Name">{item?.Name}</div>
            <div className="inventory_testimonoal_title">{item?.Title}</div>
            <div className="inventory_testimonoal_title_under_line"></div>
            <div
              dangerouslySetInnerHTML={{
                __html: item?.TestimonialText,
              }}
              className="inventory_testimonoal_description"
            ></div>
          </div>
        );
      })}
    </Carousel>
  );
};

const InventoryTestimonials = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);

  const GetDetails = (value, resetForm) => {
    setIsLoading(true);
    getTestimonial()
      .then((res) => {
        setTestimonialData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    GetDetails();
  }, []);
  return (
    <div className="inventory_testimonials_bg">
      <div className="container">
        <div className="inventory_equipment_search_title">
          {Inventory_Strings.TESTIMONOALS}
        </div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        ) : testimonialData?.length === 0 ? (
          <>
            <div
              className=" flex justify-center items-center"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: "30px",
                height: "30vh",
              }}
            >
              <div className="spinner-container no_data_text">
                No Data Found
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={
                testimonialData.length <= 3
                  ? "inventory_testimonoal_list_box"
                  : ""
              }
            >
              <InventoryTestimonoalList testimonialData={testimonialData} />
            </div>
            <div className="inventory_testimonials_learn_more_btn_box">
              <CustomButton
                lable={Inventory_Strings.LEARN_MORE}
                CustomButtonClass="inventory_testimonials_learn_more_btn"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default InventoryTestimonials;
