import { useEffect, useState } from "react";
import { ApplicationId } from "../../api/url";
import EquipmentInfo from "../../components/Equipment_Inventory_List/Equipment_Info/EquipmentInfo";
import EquipmentInventoryListBanner from "../../components/Equipment_Inventory_List/Equipment_Inventory_List_Banner/Equipment_Inventory_List_Banner";
import EquipmentSearchByCategory from "../../components/Equipment_Inventory_List/Equipment_Search_By_Category/EquipmentSearchByCategory";
import EquipmentSearchByManufacturer from "../../components/Equipment_Inventory_List/Equipment_Search_By_Manufacturer/EquipmentSearchByManufacturer";
import EquipmentWhatMore from "../../components/Equipment_Inventory_List/Equipment_What_More/EquipmentWhatMore";
import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";
import InventoryFeaturedListings from "../../components/Inventory/InventoryFeaturedListings/Inventory_featured_listings";
import InventoryEquipmentNewsLatter from "../../components/Inventory/Inventory_equipment_news_latter/Inventory_equipment_news_latter";
import InventoryEquipmentSearch from "../../components/Inventory/Inventory_equipment_search/Inventory_equipment_search";
import InventoryInfo from "../../components/Inventory/Inventory_Info/Inventory_Info";
import {
  getAllMachinesCategoryList,
  getAllMachinesManufacturerList,
  postMachinesList,
} from "../../services/FeaturedListings";
import "./EquipmentInventoryList.css";
const EquipmentInventoryList = () => {
  const [
    isLoadingMachinesCategoryTypeList,
    setIsLoadingMachinesCategoryTypeList,
  ] = useState(false);
  const [machinesCategoryTypeList, setMachinesCategoryTypeList] = useState([]);

  const [machinesManufacturerTypeList, setMachinesManufacturerTypeList] =
    useState([]);
  const [
    isLoadingMachinesManufacturerTypeList,
    setIsLoadingMachinesManufacturerTypeList,
  ] = useState(false);
  const [manufacturerName, setManufacturerName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [assetTypeId, setAssetTypeId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [inventoryList, setInventoryList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [SearchKey, setSearchKey] = useState(
    localStorage.getItem("searchFilter")
  );
  const [pageRefresh, setPageRefresh] = useState(false);
  const limit = 9;

  const InventoryList = (data) => {
    setIsLoading(true);
    let payload = {
      AssetTypeId: assetTypeId,
      PageSize: limit,
      PageNumber: data?.page ? data?.page : pageNumber,
      ApplicationId: ApplicationId,
    };

    if (data?.searchkey || SearchKey) {
      payload = {
        ...payload,
        SearchKey: data?.searchkey ? data?.searchkey : SearchKey,
      };
    }

    if (assetTypeId) {
      payload = { ...payload, ApplicationId: ApplicationId };
    }
    if (manufacturerName) {
      payload = { ...payload, ManufacturerName: manufacturerName };
    }
    postMachinesList(payload)
      .then((res) => {
        setPage(res?.data?.PageNumber);
        setTotalRecords(res?.data?.TotalRecords);
        let newList = data?.searchkey ? [] : inventoryList;
        res?.data?.machines?.map((item) => {
          const duplicateMachinery = newList.filter(
            (duplicateMachineryitem) => duplicateMachineryitem.id === item.id
          );
          if (duplicateMachinery.length === 0) {
            newList.push(item);
          }
        });
        setInventoryList(newList);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const GetMachinesCategoryTypeList = () => {
    setIsLoadingMachinesCategoryTypeList(true);
    getAllMachinesCategoryList()
      .then((res) => {
        setMachinesCategoryTypeList(res?.data);
        setIsLoadingMachinesCategoryTypeList(false);
      })
      .catch((err) => console.log(err));
  };

  const GetMachinesManufacturerTypeList = () => {
    setIsLoadingMachinesManufacturerTypeList(true);
    getAllMachinesManufacturerList()
      .then((res) => {
        setMachinesManufacturerTypeList(res?.data);
        setIsLoadingMachinesManufacturerTypeList(false);
      })
      .catch((err) => console.log(err));
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleSetAssetTypeId = (ID) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setInventoryList([]);
    setPageNumber(1);
    setAssetTypeId(ID);
  };

  const handleSetManufacturerName = (name) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setInventoryList([]);
    setPageNumber(1);
    setManufacturerName(name);
  };

  useEffect(() => {
    GetMachinesManufacturerTypeList();
    GetMachinesCategoryTypeList();
  }, []);

  useEffect(() => {
    InventoryList();
  }, [pageNumber, assetTypeId, manufacturerName]);

  useEffect(() => {
    setPageNumber(1);
    setSearchKey(localStorage.getItem("searchFilter"));

    if (pageNumber === 1) {
      InventoryList({
        searchkey: localStorage.getItem("searchFilter"),
        page: 1,
      });
    }
  }, [localStorage.getItem("searchFilter"), pageRefresh]);

  return (
    <div>
      <div className="Equipment_Inventory_Box">
        <Header
          activeTab={"EquipmentInventory"}
          pageRefresh={pageRefresh}
          setPageRefresh={setPageRefresh}
        />
        <EquipmentInventoryListBanner
          handleNextPage={handleNextPage}
          isLoading={isLoading}
          inventoryList={inventoryList}
          totalRecords={totalRecords}
          limit={limit}
          page={page}
        />
      </div>
      <InventoryEquipmentSearch
        pageRefresh={pageRefresh}
        setPageRefresh={setPageRefresh}
      />
      <EquipmentSearchByCategory
        isLoadingMachinesCategoryTypeList={isLoadingMachinesCategoryTypeList}
        machinesCategoryTypeList={machinesCategoryTypeList}
        handleSetAssetTypeId={handleSetAssetTypeId}
      />
      <EquipmentSearchByManufacturer
        machinesManufacturerTypeList={machinesManufacturerTypeList}
        isLoadingMachinesManufacturerTypeList={
          isLoadingMachinesManufacturerTypeList
        }
        handleSetManufacturerName={handleSetManufacturerName}
      />
      <InventoryFeaturedListings />
      <EquipmentWhatMore />
      <EquipmentInfo />
      <InventoryEquipmentNewsLatter />
      <Footer />
    </div>
  );
};
export default EquipmentInventoryList;
