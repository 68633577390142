import { About, Inventory_Strings } from "../../../Utils/Constants";
import CustomButton from "../../custom_components/CustomButton";
import "./AboutInfoData.css";
const AboutInfoData = () => {
  return (
    <div className="about_info_bg">
      <div className="container">
        <div className="inventory_info_title_1">{About.CUSTOMER_SERVICE}</div>
        <div className="about_info_title_2">{About.LIKE_EQUIPMENT}</div>

        <div className="about_info_title_2">{About.GIVE_TADAY}</div>

        <div className="about_info_data_number">{About.NUMBER}</div>
        <div className="inventory_info_learn_more_btn_box">
          <CustomButton
            lable={Inventory_Strings.LEARN_MORE}
            CustomButtonClass="inventory_info_learn_more_btn"
          />
        </div>
      </div>
    </div>
  );
};
export default AboutInfoData;
