import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import About from "./Page/About/About";
import Details from "./Page/Detail/Detail";
import EquipmentInventoryList from "./Page/EquipmentInventoryList/EquipmentInventoryList";
import Inventory from "./Page/Inventory/Inventory";
import Sell from "./Page/Sell/Sell";
import Rent from "./Page/Rent/Rent";
import Service from "./Page/Service/Service";
import XhdDealer from "./Page/Xhd-Dealer/Xhd_Dealer";
import XhdRubberTracks from "./Page/Xhd-Rubber-Tracks/Xhd_Rubber_Tracks";
import UsedAttachment from "./Page/Used-Attachments/Used_Attachments";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Inventory />}></Route>
        <Route path={"/about"} element={<About />}></Route>
        <Route path={"/inventory"} element={<EquipmentInventoryList />}></Route>
        <Route path={"/sell"} element={<Sell />}></Route>
        <Route path={"/rent"} element={<Rent />}></Route>
        <Route path={"/service"} element={<Service />}></Route>
        <Route
          path={"/xhd-skidsteer-attachments"}
          element={<XhdDealer />}
        ></Route>
        <Route
          path={"/xhd-rubber-tracks"}
          element={<XhdRubberTracks />}
        ></Route>
        <Route path={"/used-attachments"} element={<UsedAttachment />}></Route>
        <Route path={"/detail/:id"} element={<Details />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
