import Header from "../../components/Header/header";
import InventoryBenner from "../../components/Inventory/InventoryBenner/inventory_benner";
import InventoryExplore from "../../components/Inventory/InventoryExplore/Inventory_explore";
import InventoryHelp from "../../components/Inventory/InventoryHelp/Inventory_help";
import "./Inventory.css";
import InventoryEquipmentSearch from "../../components/Inventory/Inventory_equipment_search/Inventory_equipment_search";
import InventoryFeaturedListings from "../../components/Inventory/InventoryFeaturedListings/Inventory_featured_listings";
import InventoryEquipmentNewsLatter from "../../components/Inventory/Inventory_equipment_news_latter/Inventory_equipment_news_latter";
import InventoryTestimonials from "../../components/Inventory/Inventory_testimonials/Inventory_testimonials";
import InventoryInfo from "../../components/Inventory/Inventory_Info/Inventory_Info";
import Footer from "../../components/Footer/footer";
const Inventory = () => {
  return (
    <div className="Inventory_Top_Box">
      <Header activeTab={"Home"}/>
      <div className="container">
        <InventoryBenner />
      </div>
      <InventoryHelp />
      <InventoryExplore />
      <InventoryFeaturedListings />
      <InventoryEquipmentSearch />
      <InventoryTestimonials />
      <InventoryInfo />
      <InventoryEquipmentNewsLatter />
      <Footer />
    </div>
  );
};
export default Inventory;
