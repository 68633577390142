import MachineImg from "../assets/Img/Home/Placement_Area_ASSETsmallSIZED_kr.png";
import BarryImage from "../assets/Img/About/Barry.png";
import ParsonImage from "../assets/Img/About/parsons.png";
import HeavyEquipment from "../assets/Img/Inventory/Heavy_Equipment.jpg";
import Logo1 from "../assets/Img/About/cat_small.png";
import Logo3 from "../assets/Img/About/komatsu.png";
import Logo4 from "../assets/Img/About/john_deere.png";
import Logo5 from "../assets/Img/About/volvo.png";

import loaders from "../assets/Img/Inventory/loaders.png";
import dozers from "../assets/Img/Inventory/dozers.png";
import attachments from "../assets/Img/Inventory/attachments.png";

export const ReCAPTCHA_sitekey = "6LcBpvUkAAAAAJ5zol3V7zXPUw2M5nOxtNQmJT7r";

export const Contact_Content = {
  CONTACT_US: "CONTACT US",
  CONTACT_FORM: "CONTACT FORM",
  CONTACT_INFO: "Contact Info",
  SECURITY_PURPOSES:
    "For security purposes, please enter the characters from the box above:",
  Tell_US_MORE: "tell us more",
};

export const Service_Content = {
  SERVICE_REPAIR: "SERVICE & REPAIR",
  FORM_HEADER: "WORK ORDER REQUEST",
  FORM_SUB_HEADER:
    "Contact our service department for immediate assistance or complete the form and we will get back to you asap.",
  FASTAFFORDABLE: "Fast and Affordable",
  EXPERTMACHANICS: "Expert Mechanics",
  MIMIALTIME: "Minimal Wait Time",
  EQQUIPPEDSHOP: "Fully Equipped Shop",
  ALLMAKESMODELS: "All Makes, All Models",
  SHOPFIELDSERVICE: "Shop and Field Service",
  SERVICEADDRESS: "2396 Pleasantdale Rd. Atlanta, GA 678-637-1782",
  DOLLARS: "$125",
  SLASH: "/Hr",
  SHOPRATE: "Shop Rate",
  ABOUT_INVENTORY:
    "Our ever-changing inventory includes crawler dozers, excavators, lifts, loader backhoes, motor graders, skid steers, wheel loaders, smooth drum and padfoot compactors, log loaders, motor scrapers, telehandlers, off-road articulated dump trucks (ADTs), vertical drills, industrial tractors, and more.",
};

export const XHD_DEALER_Content = {
  SKIDSTEER: "XHD Skidsteer Attachments",
  RUBBER: "XHD Rubber Tracks",
  FORM_HEADER: "receive a quote",
  FORM_SUB_HEADER:
    "Contact our sales department for immediate assistance or complete the form and we  will get back to you asap.",
};

export const USED_ATTACHMENTS = {
  USED_ATTACHMENTS: "USED ATTACHMENTS",
};

export const header = {
  CALL_NOW: "CALL NOW",
  NUMBER: "(678) 637-1782",
  SIMONSON_EQUIPMENT: "SIMONSON EQUIPMENT",
  INVENTORY: "INVENTORY",
  XHD_DEALER: "XHD DEALER",
  XHD_RUBBER_TRACKS: "XHD RUBBER TRACKS",
  USER_ATTACHMENTS: "USED ATTACHMENTS",
  XHD_SKIDSTEER_ATTACHMENTS: "XHD SKIDSTEER ATTACHMENTS",
  ABOUT: "ABOUT",
  BLOG: "BLOG",
  CONTACT: "CONTACT",
  SELL: "SELL",
  RENT: "RENT",
  SERVICE: "SERVICE",
  CALL_NOW: "CALL NOW",
};

export const footer = {
  Simonson_Equipment: "Simonson Equipment",
  Address:"2396 Pleasantdale Rd Atlanta, GA 30340",
  Located_Atlanta:
    "Located in Atlanta, Georgia, we serve clients around the world that need high quality heavy equipment and attachments.",
  Simonson: "Simonson Equipment.",
  Powered_by_Vizy: "Powered by",
  Vizybility: "Vizybility",
  Designed_by: "Designed by",
  Galfas_Media: "Galfas Media",
  Rights_Reserved: "All Rights Reserved.",
};

export const Inventory_Strings = {
  BUY: "BUY",
  SELL: "SELL",
  RENT: "RENT",
  TRADE: "TRADE",
  CONSIGN: "CONSIGN",
  FIND_EQUIPMENT: "FIND EQUIPMENT",
  WERE_HERE_TO_HELP: "WE'RE HERE TO HELP",
  LEARN_MORE: "Learn More",
  EXPORE: "EXPORE",
  INVENTORY: "INVENTORY",
  FOR_SELE: "FOR SALE",
  FEATURED_LISTINGS: "FEATURED LISTINGS",
  EQUIPMENT_SEARCH: "EQUIPMENT SEARCH",
  SEARCH_MANUFACTURER: "Search by manufacturer, equipment type, etc.",
  SEARCH: "SEARCH",
  NEWSLETTER: "NEWSLETTER",
  SIGN_UP: "Sign up for updates on our latest inveṇtory.",
  SUBSCRIBE: "Subscribe",
  TESTIMONOALS: "TESTIMONIALS",
  NO_MATTER:
    "No matter where you are in the process, we can save you time and money.",
  LEARN_CUSTOMERS:
    "Learn how we have saved our customers time and money for over 20 years.",
  CONTACT_US: "CONTACT US",
  CALL_NOW: "call now",
  SUBMIT: "Submit",
  CANCEL: "cancel",
};

export const About = {
  ABOUT_US: "ABOUT US",
  Yard_Address: "Yard Address",
  Yard_Address_String: "2396 Pleasantdale Rd Atlanta, GA 30340",
  Lorem_amet:
    "Simonson Equipment buys and sells used heavy machinery and consignment pieces. Located in Atlanta, Georgia, we serve the American South and a nationwide customer base of contractors, dealers, rental agencies, construction companies, mining companies, and others—anyone with a need for quality heavy equipment and attachments. We also export machines to international customers in Africa and beyond.",
  About_Inventory: "ABOUT OUR INVENTORY",
  About_Includeing:
    "We deal all makes and models,includeing Bobcat, CASE, Caterpillar, Challenger, Deere, Gehl, Komatsu, Takechi, and Volvo, among others.",
  CUSTOMER_SERVICE: "CUSTOMER SERVICE GUARANTEED",
  LIKE_EQUIPMENT: "Like our equipment, our customer server is second to none.",
  GIVE_TADAY: "Give us a call today",
  NUMBER: "678-637-1782",
};

export const Equipment_content = {
  WANT_MORE: "WANT MORE?",
  DONT_SEE:
    "Dont't see what you're looking for?Give us a call and we'll search our lots",
};

export const Equipment_Inventory_List = {
  INVENTORY: "INVENTORY",
  Explor_Selection:
    "Explore a selection of our inventory below. Our fleet changes all the time!",
  LOAD_MORE: "LOAD MORE",
  SEARCH_CATEGORY: "SEARCH BY CATEGORY",
  SEARCH_MANUFACTURER: "SEARCH BY MANUFACTURER",
  VIEW_ALL: "VIEW ALL",
};

export const Equipment_SearchBy_Category_List = [
  {
    img: loaders,
  },
  {
    img: dozers,
  },
  {
    img: attachments,
  },
];

export const About_Us_List = [
  {
    img: BarryImage,
    name: "Barry Simon",
    email: "barry@simonsonequipment.com",
    number: "678-770-3159",
  },
  {
    img: ParsonImage,
    name: "Daniel Simon",
    email: "daniel@simonsonequipment.com",
    number: "678-637-1782",
  },
];

export const Testimonoal_list = [
  {
    title: "Easy Process",
    description:
      "Just as easy, if not easier, than dealing with my local dealers from the initial phone call to financing to closing the deal.",
  },
  {
    title: "Honest Business People",
    description:
      "Lorem ipsum dolor sit amer, consetetur sadipscing elitr, sed diam, noumy ermod tempor invidint ut labore et dolore magna. Lorem ipsum dolor sit amer, consetetur sadipscing elitr, sed diam.",
  },
  {
    title: "Dan is The Man",
    description:
      "Assisted with financing, communications were thorough and consistent, they even aired my trailer tires on my way out!",
  },
];

// remove last
export const Inventory_Machine_list = [
  {
    img: MachineImg,
    title: "YEAR MAKE MODEl11",
    price: "$203,000",
  },
  {
    img: MachineImg,
    title: "YEAR MAKE MODEl11",
    price: "$203,000",
  },
  {
    img: MachineImg,
    title: "YEAR MAKE MODEl11",
    price: "$203,000",
  },
];

export const Inventory_Help_List = [
  {
    title: "BUY",
    description:
      "We know how to find exactly what you need - even if we don't have it in our yard.",
    link: "/inventory",
  },
  {
    title: "SELL",
    description:
      "Want to sell or trade but don't have the time to make it happen? See how we can help.",
    link: "/sell",
  },
  {
    title: "RENT",
    description:
      "Our rental inventory grows daily.Only need the equipment until thw job is done? we've got you covered.",
    link: "/rent",
  },
];

export const About_Inventory_Logo_List = [
  {
    img: Logo1,
  },
  {
    img: Logo3,
  },
  {
    img: Logo4,
  },
  {
    img: Logo5,
  },
];

export const Machine_List = [
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
  {
    Img: HeavyEquipment,
    Title: "YEAR MAKE MODEL",
    Price: "$209,000",
  },
];

export const footer_Link = [
  {
    title: "Services",
    subLink: [
      {
        title: "For Sale",
        link: "/inventory",
      },
      {
        title: "For Rent",
        link: "/rent",
      },
      {
        title: "Sell Your Equipment",
        link: "/Sell",
      },
    ],
  },
  {
    title: "Resources",
    subLink: [
      {
        title: "FAQs",
        link: "/about",
      },
      {
        title: "Blog",
        link: "/",
      },
      {
        title: "Financing",
        link: "/",
      },
    ],
  },
];

export const social_links = {
  facebook: "https://www.facebook.com/groups/heavyequipmentforsalepage",
  linkedin: "https://www.linkedin.com/in/daniel-simon-b673a59/",
  instagram: "https://www.instagram.com/heavyequipmentdealer/",
  youtube: "https://www.youtube.com/@simonsonequipment",
};

export const sell_string = {
  hedging: "LOOKING TO BUY",

  sell_details: [
    {
      title: "Hitachi, Deere, or Komatsu (any brand) 130-160 size excavators",
      subTitle: "- Under 4,000 hours with aux hydraulics. Thumb is a plus",
    },
    {
      title: "John Deere 700J or 700K",
      subTitle: "- with cab, sweeps, and a ripper",
    },
    {
      title: "Cat D8N 5TJ Series",
      subTitle: "- Prefer low hours and in good condition",
    },
    {
      title: "Cat 305E2 CR or similar",
      subTitle: "- Prefer under 3000 hours and a cab but will consider any",
    },
    {
      title: "Cat D6K LGP",
      subTitle: "- with under 5000 hours",
    },
    {
      title: "ROPS/CAB FOR A CAT 963D",
    },
    {
      title: "Heavy duty quarry dump trailers.",
      subTitle: "- no D.O.T but needs to be nice, no junk",
    },
  ],
};

export const rent_string = {
  hedging:
    "At Simsonson Equipment we Rent many late and low hour machines for your short and long term rental needs.  We specialize in 20 to 50 Ton excavators, 20 to 50 ton Articulated Haul trucks and 50 to 300 Hp BullDozers And More. (D3 to D8)",
  subHeading: "Contact us to request a machine for your fleet.",
};
